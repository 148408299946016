@import "src/index";

@mixin inputStyle {
  .wrapper {
    &_error {
      border: 2px solid $primary;
    }

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #FF0000;
    border-radius: 32px;
    padding: 8px 20px;
    width: 100%;
    margin-bottom: 16px;
    background-color: white;
    position: relative;

    @media (max-width: 425px) {
      margin-bottom: 34px;

      &.mobileStyle{
        margin-top: 20px;
        .placeholderWrapper{
          top: -42px;
        }
      }
    }

    .placeholderWrapper {
      font-size: 14px;
      color: $secondary;
      opacity: 0.5;
      width: 100%;

      @media (max-width: 425px) {
        position: absolute;
        left: 0;
        top: -22px;
      }
    }

    .input {
      border: 0;
      font-size: 16px;
      text-overflow: ellipsis;

      &_error {
        color: $primary;
      }

      &:disabled {
        background-color: rgba(0,0,0,0);
      }

      &::placeholder {
        color: transparent;

        @media (max-width: 425px) {
          color: $secondary;
          opacity: 0.5;
        }

      }
    }
    .textarea{ 
      border: none;
      font-size: 16px;
      width: 100%;
      height: 130px;
      resize: none;
      &_error {
        color: $primary;
      }
      &::placeholder{
        opacity: 0;
      }
      @media(max-width: 425px){
        &::placeholder{
          color: $secondary;
          opacity: 0.5;
        }
      }
    }
  }
}

.displayNone{
  @media(max-width: 425px){
    display: none;
  }
}

@include inputStyle;


@import "src/index";
@import "src/common/InputField/InputField.module";

.wrapper {
  @include inputStyle;
  border: 1px solid $primary;
  padding-right: 50px;
  &_error {
    border: 2px solid $primary;
  }

  &:hover {
    cursor: pointer;
  }

  .placeholderWrapper {
    position: relative;
    @media (max-width: 425px) {
      position: absolute;
      display: none;
    }
  }

  .placeholderWrapper_mob {
    display: none;
    position: relative;
    @media (max-width: 425px) {
      display: block;
      position: absolute;
    }
  }

  .cross{   
      right: 40px;
      position: absolute;
      top: 16px;
      font-size: 12px;
      @media (max-width: 425px) {
        top: 12px;
      }  
  } 

  .input {
    color: black;

    &:hover {
      cursor: pointer;
    }

    &_error {
      color: $primary;
    }
  }

  .arrow {
    position: absolute;
    top: 12px;
    right: -30px;
    transform: rotate(0);
    transition: all 0.3s ease;

    @media (max-width: 425px) {
      top: 36px;
      right: 10px;
    }

    &_active {
      transform: rotate(180deg);
    }
  }

  .hr {
    margin: 0;
    border: 0;
    border-bottom: 1px solid $primary;
    transform: scaleX(0);
    transition: all 0.3s ease;

    &_active {
      transform: scaleX(1);

      @media (max-width: 425px) {
        margin-top: 15px;
      }
    }
  }

  .dropdownContent {
    max-height: 0;
    overflow: scroll;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, .5);
    }

    .contentInner {
      transition: all 0s ease;
      margin: 0;
      padding: 10px 0;

      @media (max-width: 425px) {
        padding: 5px 0;
      }

      &:hover {
        color: $primary;
      }
    }
  }

  .dropdownActive {
    max-height: 250px;
    visibility: visible;
    //transform: scaleY(1);
    opacity: 1;
    transition: all 0.5s;
  }

}


@import "src/index";

$bgColor: #ededed;

.wrapper {
  height: 510px;
  background: $bgColor;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 1300px) {
    height: unset;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 85%;
    align-self: center;
    max-width: 1400px;
  }

  h1 {
    font-family: Geometria-Heavy, sans-serif;
    text-transform: uppercase;
    padding-top: 55px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 60px;
    line-height: 1em;
    color: $bgColor;
    stroke: black;
    stroke-width: 1px;
    -webkit-text-stroke-color: black;
    -webkit-text-stroke-width: 1px;
    margin-bottom: 8px;
    @media(max-width:414px){
      font-size: 36px;
    }
  }
  .line{
    display: none;
    @media(max-width:768px){
      display: block;
      position: absolute;
      top: 11%;
      left: 5%;
      width: 8px;
      background-color: #FFEA00;
      height: 93%;
      z-index: 1;
    }
  }
  .stairs {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
      padding-left: 32px;
    }
  }
  button {
    font-family: Geometria-Heavy, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    color: #333333;
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    margin: auto;
    border: 0;
    background: linear-gradient(90deg, #ffeb00 0%, #ff9e00 99%);
    border-radius: 31px;
    padding: 17px 26px 16px 25px;
    transition: box-shadow 0.5s ease;
    width: 18ch;
    cursor: pointer;
    &:hover {
      box-shadow: 0 3px 9px 0 #e0cf06;
    }
    @media(max-width: 768px){
      width: 90%;
    }
  }
  .polygon {
    width: 100%;
    height: 300px;
    background: white;
    clip-path: polygon(0 0, 0 118px, 100% 1px, 100% 0);
    position: absolute;
    bottom: -299px;
    background: $bgColor;
  }

  .btn {
    top: 0;
    bottom: auto;
  }
}

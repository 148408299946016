$tShirtText: #c50103;

@mixin label {
  font-family: Geometria-Bold, sans-serif;
  font-size: 60px;
  line-height: 100%;
  color: #ff0000;

  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 100%;
  }
}

.stepFive {
  padding: 40px 130px 0 86px;
  margin: 0 auto 127px auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.desc {
  &_wrapper {
    display: flex;
    flex-direction: row;
    // padding: 40px 130px 0 130px;
    //padding-top: 40px;
    position: relative;
    @media (max-width: 1300px) {
      background-position-x: 0%;
    }
  }
  &_img {
    margin-right: 30px;
    width: 218px;
    img {
      //width: 100%;
      width: 218px;
      height: 218px;
    }
  }
  &_bgImg {
    z-index: -1;
    max-width: 381px;
    position: absolute;
    top: 40px;
    right: 0;
    img {
      width: 100%;
    }
  }
  &_text {
    max-width: 695px;
    margin-bottom: 50px;
    .text_step {
      display: none;
    }
    .text_title {
      @include label;
      text-transform: uppercase;
      font-weight: 900;
      margin-bottom: 12px;
      font-family: Geometria-Heavy, sans-serif;
    }
    .text_desc {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      max-width: 560px;
      p {
        margin: 0;
        &:last-child {
          margin-top: 20px;
        }
      }
     span{
        color: #ff0000;
        font-weight: 600;
      }
    }
  }
}

.form {
  &_wrapper {
    padding-bottom: 30px;
    background-image: url(../../../assets/images/BigSteps/StepFive/tshirt.png);
    background-repeat: no-repeat;
    background-position-x: 0%;
    background-position-y: 40%;
    background-size: 36%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 416px;
      height: 250px;
      background-image: url(../../../assets/images/BigSteps/StepFour/border-bottom.png);
      background-repeat: no-repeat;
      background-size: auto;
    }

    .tShirtText {
      position: absolute;
      text-align: center;
      top: 32%;
      left: 17.5%;
      font-family: Geometria-Heavy, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
      max-width: 10ch;
      color: $tShirtText;
      overflow-wrap: break-word;
      hyphens: auto;
      opacity: 0.8;
      @media (max-width: 1300px) {
        font-size: 20px;
      }
      @media (max-width: 1024px) {
        // display: none;
        font-size: 10px;
        left: 50%;
        top: 25%;
      }
    }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   top: 49%;
    //   left: 0;
    //   width: 300px;
    //   height: 100px;
    //   background-image: url(../../../assets/images/BigSteps/StepOne/dots2.png);
    //   background-repeat: no-repeat;
    //   z-index: -1;
    // }
  }
  // &_catsTalk {
  //   position: absolute;
  //   right: 29%;
  //   max-width: 352px;
  //   padding: 32px;
  //   border: 1px solid #ff0000;
  //   font-weight: bold;
  //   font-size: 24px;
  //   line-height: 30px;
  //   color: #333333;
  //   border-radius: 24px;
  //   @media (max-width: 1200px) {
  //     max-width: 250px;
  //     padding: 12px;
  //   }
  //   &::after {
  //     content: "";
  //     position: absolute;
  //     border-left: 24px solid transparent;
  //     border-top: 20px solid #ff0000;
  //     bottom: -20px;
  //     left: 50px;
  //   }

  //   span {
  //     color: #ff0000;
  //   }
  // }
  &_mobileBorder {
    display: none;
  }
  &_title {
    padding-top: 116px;
    background-image: url(../../../assets/images/BigSteps/StepFour/border-top.png);
    background-repeat: no-repeat;
    // background-position-x: 27%;
    // background-position-y: center;
    padding-bottom: 34px;
    color: #ff0000;
    div {
      font-family: Geometria-Heavy, sans-serif;
      max-width: 560px;
      margin-left: auto;
      margin-right: 100px;
      text-align: left;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
    }
    .limit{
      font-weight: bold;
      font-size: 15px;
      line-height: 100%;
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin-left: auto;
    margin-right: 100px;
    margin-bottom: -4px;
  }
  .input_agreement {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }
  &_btn {
    display: flex;
    flex-direction: column;
    padding-bottom: 132px;
    max-width: 560px;
    margin-left: auto;
    margin-right: 100px;
    div {
      width: 165px;
    }
  }
  &_mobileImg {
    display: none;
  }
}

.footer {
  margin-bottom: 67px;
  &_wrapper {
    display: flex;
    flex-direction: row;
  }
  &_text {
    font-family: Geometria-Heavy, sans-serif;
    margin-right: 87px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      font-family: Geometria-Heavy, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      color: #ff0000;
      margin-bottom: 24px;
      margin-top: 0;
    }
    p {
      margin-top: 0;
    }
    a, span {
      color: #ff0000;
    }
  }
}

.img_title {
  font-family: Geometria-Heavy, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  color: #ff0000;
  margin-bottom: 30px;
  margin-top: 15px;
}
.img_socialmedia {
  display: none;
}

.disabled{
  background: #ccc;
  border: 1px solid #ccc;
  pointer-events: none;
}
.desktopImg{
  display: flex;
  flex-direction: row;
  a{
    margin-right: 16px;
  }
  :last-child{
    margin-right: 0;
  }
 
}
@media (max-width: 1024px) {
  .stepFour {
    // background-image: none;
    background-size: 298px;
    background-position-x: 100%;
    background-position-y: 0%;
  }
  .desc {
    background-image: url(../../../assets/images/BigSteps/StepFour/yellow-circle.png);
    background-repeat: no-repeat;
    background-position-y: -40%;

    &_wrapper {
      background-image: none;
      // max-width: 463px;
      padding: 0;
      max-width: 463px;
      margin: 0 auto;
    }
    &_img {
      display: none;
    }
    &_bgImg {
      display: none;
    }
    &_img {
      width: 100%;
    }
    &_text {
      margin-bottom: 40px;
      width: 100%;
      padding-top: 40px;
      .text_title {
        font-size: 33px;
        line-height: 120%;
      }
      .text_desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
      }
      .text_step {
        display: block;
        font-weight: 900;
        font-family: Geometria-Heavy, sans-serif;
        font-size: 36px;
        line-height: 120%;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #000000;
        color: transparent;
      }
    }
  }

  .form {
    &_wrapper {
      background-image: none;
      padding: 0 20px;
      max-width: 463px;
      margin: 0 auto;

      &::after {
        background-image: none;
      }
      &::before {
        display: none;
      }
    }
    &_mobileBorder {
      display: block;
      background-image: url(../../../assets/images/BigSteps/StepFive/tshirt.png);
      background-position-y: 70%;
      background-position-x: right;
      background-repeat: no-repeat;
      background-size: 90%;
      height: 400px;
      @media (max-width: 1024px) {
        background-position-x: center;
        background-size: 75%;
      }
      @media (max-width: 768px) {
        background-position-x: center;
        background-size: 75%;
      }
      img {
        width: 125%;
        position: relative;
        left: -30px;
      }
    }
    &_title {
      max-width: 463px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 22px;
      line-height: 100%;
      background-image: none;
      text-transform: unset;
      margin: 0 auto;
      margin-top: 20px;
      div {
        margin: 0;
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 10px;
      }
    }
    &_inputs {
      margin: 0 auto;
    }
    &_btn {
      padding: 0;
      margin: 0 auto;
      margin-bottom: 14px;
      div {
        width: 100%;
      }
    }
    &_mobileImg {
      display: flex;
      padding-bottom: 150px;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 463px;
      margin: 0 auto;
      .mobileImg_text {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        max-width: 75%;
        position: absolute;
        left: 0;
        right: 0;
        padding: 16px 52px 16px 52px;
        border: 1px solid #ff0000;
        border-radius: 24px;
        color: #333333;
        z-index: -1;
        div {
          font-family: Geometria-Heavy, sans-serif;
        }
        div:last-child {
          color: #ff0000;
        }
      }
      img {
        width: 50%;
        object-fit: contain;
      }
    }
  }
  .footer {
    padding: 0 20px;
    &_wrapper {
      max-width: 463px;
      margin: 0 auto;
      flex-direction: column;
    }
    &_text {
      margin: 0;
    }
    &_img {
      .desktopImg {
        display: none;
      }
    }
    .img_title {
      font-size: 36px;
      line-height: 120%;
    }
    .img_socialmedia {
      display: flex;
      flex-direction: row;
      a{
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: 767px) {
  .stepFour {
    background-image: none;
  }
  .desc {
    padding: 0 20px;
    &_wrapper {
      background-image: none;
      padding: 0;
    }
  }
  .form {
    &_inputs {
      background-image: none;
    }
  }
}

@import "src/index";

.step {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 0 10px;
  transform: translate(0, 150px);
  visibility: hidden;
  opacity: 0;
  @media (max-width: 768px) {
    width: 100%;
  }

  &._active {
    transform: translate(0, 0);
    visibility: visible;
    opacity: 1;
  }

  $duration: 0.7s;
  $start: 0s;

  &:nth-child(1) {
    margin-top: 67px;
    transition: all $duration ease $start;
  }

  &:nth-child(2) {
    margin-top: 47px;
    transition: all $duration ease $start + 0.35;
  }

  &:nth-child(3) {
    margin-top: 27px;
    transition: all $duration ease $start + 0.7;
  }

  &:nth-child(4) {
    margin-top: 7px;
    transition: all $duration ease $start + 0.95s;
  }

  &:nth-child(5) {
    margin-top: -13px;
    transition: all $duration ease $start + 1.2;
  }

  img {
    width: 124px;
    height: 124px;
  }

  h3 {
    font-size: 18px;
    font-family: Geometria-Bold, sans-serif;
    color: $primary;
    margin-bottom: 0;
    margin-top: 12px;
  }

  p {
    font-family: Geometria-Light, sans-serif;
    color: $secondary;
    margin-top: 12px;
  }
}

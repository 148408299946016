@import "src/index";

.item {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  &:nth-child(1) {
    @media(max-width: 960px){
      margin-top: 0 ;
    }
  }

  &:nth-child(2) {
    p {
      width: 16ch;
    }
  
    @media(max-width: 450px){
      margin-top: 40px;
    }
  }

  &:nth-child(3) {
    @media(max-width: 960px){
      margin-top: 40px;
    }
  }

  &:nth-child(4) {
    @media(max-width: 960px){
      margin-top: 40px;
    }
  }

  span {
    font-size: 70px;
    text-shadow: 0 4px 20px #fab000ad;
  }

  h3 {
    line-height: 1.5em;
    margin: 26px 0 0 0;
    color: $primary;
    font-family: Geometria-Bold, sans-serif;
  }

  p {
    margin: 0;
    width: 20ch;
    line-height: 1.3em;
  }
  @media(max-width: 960px){
    margin-top: 0 ;
    flex-basis: 45%;
  }
}
@import "src/index";


.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  .bgImg{
    position: absolute;
    width: 250px;
    top:45%;
    right: -40%;
    @media(max-width:1400px){
      right: -60%;
    }
    @media(max-width:1000px){
      display: none;
    }
  }
  .catsTalk{
    position: absolute;
    top: 29%;
    left: 107%;
    width: 300px;
    padding: 32px;
    border: 1px solid #ff0000;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
    border-radius: 24px;
    &::after {
      content: "";
      position: absolute;
      border-left: 24px solid transparent;
      border-top: 20px solid #ff0000;
      bottom: -20px;
      left: 50px;
    }
    @media(max-width:1400px){
      width: 220px;
    }
    @media(max-width:1000px){
      display: none;
    }
  }
  .formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;
    align-self: center;
    h1 {
      font-size: 3.75em;
      color: $primary;
      font-family: Geometria-Bold, sans-serif;
      //margin-top: 4.56rem;
      margin-top: 3rem;
      margin-bottom: 0;

      @media (max-width: 425px) {
        margin-top: 2.8rem;
        margin-bottom: 0;
        width: 90%;
        font-size: 2em;
      }

    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      position: relative;
      padding-top: 2rem;

      @media (max-width: 1400px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 80%;
      }

      @media (max-width: 425px) {
        width: 90%;
      }

      .bg {
        position: absolute;
        z-index: -1;
        top: -8%;
        display: block;

        @media (max-width: 425px) {
          display: none;
        }
      }

    }

  }

  .disabled{
    background: #ccc;
    border: 1px solid #ccc;
    pointer-events: none;
  }

  .agree {
    display: flex;
    align-items: center;
    width: 100%;
    color: $secondary;
    font-size: 14px;

    p {
      opacity: 0.5;
      margin-left: 5px;
    }
  }

  .checkbox {
    transform: scale(1);
    &_error {
      animation: shake 1.2s ease-in-out;
    }
  }

  @keyframes shake {
    0%, 50%, 100% {
      transform: rotate(0deg);
    }
    10%, 30% {
      transform: rotate(-10deg);
    }
    20%, 40% {
      transform: rotate(10deg);
    }
  }

}
@font-face {
  font-family: 'Geometria-Light';
  font-weight: 300;
  src:  url('assets/fonts/Geometria-Light.ttf')  format('truetype');
}

@font-face {
  font-family: 'Geometria';
  font-weight: 400;
  src:  url('assets/fonts/Geometria.ttf')  format('truetype');
}

@font-face {
  font-family: 'Geometria-Bold';
  font-weight: 700;
  src:  url('assets/fonts/Geometria-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'Geometria-Heavy';
  font-weight: 900;
  src:  url('assets/fonts/Geometria-Heavy.ttf')  format('truetype');
}


$primary: #FF0000;
$secondary: #333333;
$progressColor: orange;
$bgColor: white;

*:focus {
  outline: none;
  
}
*{
  font-family: Geometria, sans-serif;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: Geometria, sans-serif;
  overflow-x: hidden;
}


@import "src/index";

$hoverColor: #FFD4D4;

.wrapper {
  $border: 1px;

  position: relative;
  border-radius: 100px;
  padding: 13px 32px;
  border: $border solid $secondary;
  margin-bottom: 20px;
  transition: border 0.4s ease;
  background: white;
  background-clip: padding-box;
  text-align: center;
  font-family: Geometria-Light, sans-serif;

  &:hover {
    cursor: pointer;
    border: $border solid transparent;

    @media (max-width: 1024px) {
      border: $border solid $secondary;
    }

    &:after {
      content: "";
      position: absolute;
      top: -$border;
      bottom: -$border;
      right: -$border;
      left: -$border;
      border-radius: 100px;
      background: linear-gradient(90deg, rgba(255,235,0,1) 0%, rgba(255,158,0,1) 99%);
      z-index: -1;

    }
  }

  &:active {
    background: linear-gradient(90deg, rgba(255,235,0,1) 0%, rgba(255,158,0,1) 99%);
  }
}

.active {
  background: linear-gradient(90deg, rgba(255,235,0,1) 0%, rgba(255,158,0,1) 99%);
  font-weight: 600;
}
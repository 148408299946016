$progressColor: orange;
$bgColor: white;

@mixin label {
  font-family: Geometria-Bold, sans-serif;
  font-size: 60px;
  line-height: 100%;
  color: #ff0000;

  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 100%;
  }
}

.stepOne {
  padding: 40px 130px 0 86px;
  margin: 0 auto 127px auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.morePresents {
  display: flex;
  flex-direction: column;
  &__text {
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 100%;
    color: #333333;
    margin-bottom: 32px;
    @media (max-width: 450px) {
      font-size: 22px;
    }
  }
  &__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 450px) {
      flex-direction: column;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;
    img {
      margin-bottom: 20px;
    }
  }
  .block__title {
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #ff0000;
  }
  .block__desc {
    font-family: Geometria;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #333333;
  }
}
.btn_container {
  display: flex;
  justify-content: space-between;
}
.desc {
  &_wrapper {
    display: flex;
    flex-direction: row;
    // padding: 40px 130px 0 130px;
    //padding-top: 40px;
    position: relative;
    @media (max-width: 1300px) {
      background-position-x: 0%;
    }
  }

  &_img {
    margin-right: 20px;
    width: 218px;
    height: 218px;

    img {
      width: 218px;
      height: 218px;
    }
  }

  &_dotsImg {
    z-index: -1;
    max-width: 381px;
    position: absolute;
    top: -50px;
    right: 0;

    img {
      width: 100%;
    }
  }

  &_text {
    max-width: 695px;
    margin-bottom: 50px;

    .text_step {
      display: none;
    }

    .text_title {
      @include label;
      text-transform: uppercase;
      font-weight: 900;
      margin-top: 21px;
      margin-bottom: 12px;
      font-family: Geometria-Heavy, sans-serif;
    }

    .text_desc {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      max-width: 560px;

      p {
        margin: 0;
      }

      span {
        color: #ff0000;
        font-weight: 600;
      }
    }
  }
}
.cat {
  position: absolute;
  right: 11%;
  top: 9%;
  @media (max-width: 1560px) {
    right: 12%;
  }
  @media (max-width: 1270px) {
    right: 0;
  }
  @media (max-width: 1200px) {
    width: 30%;
  }
  @media (max-width: 1024px) {
    width: 250px;
    right: 25%;
    top: 32%;
  }
  @media (max-width: 615px) {
    top: 20%;
    right: 8%;
  }
  @media (max-width: 450px) {
    width: 200px;
    top: 24%;
    right: -2%;
  }
}
.form {
  &_wrapper {
    padding-bottom: 30px;
    // background-image: url(../../../assets/images/BigSteps/StepOne/stepOneCat.png);
    // background-repeat: no-repeat;
    // background-position-x: 58%;
    // background-position-y: 243%;
    // background-size: 32%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 27%;
      left: 0;
      width: 300px;
      height: 100px;
      background-image: url(../../../assets/images/BigSteps/StepOne/dots2.png);
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  &_catsTalk {
    position: absolute;
    right: 10%;
    max-width: 352px;
    padding: 32px;
    border: 1px solid #ff0000;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
    border-radius: 24px;

    @media (max-width: 1560px) {
      right: 11%;
    }
    @media (max-width: 1270px) {
      right: 0;
    }
    @media (max-width: 1200px) {
      max-width: 250px;
      padding: 12px;
    }

    &::after {
      content: "";
      position: absolute;
      border-right: 24px solid transparent;
      border-top: 20px solid #ff0000;
      bottom: -20px;
      right: 50px;
    }

    span {
      color: #ff0000;
    }
    div {
      // width: 250px;
      height: 90px;
      overflow: hidden;
      // white-space: nowrap;
      // display: inline-block;

      text-overflow: ellipsis;
    }
  }

  &_title {
    padding-top: 116px;
    background-image: url(../../../assets/images/BigSteps/StepFour/border-top.png);
    background-repeat: no-repeat;
    // background-position-x: 27%;
    // background-position-y: center;
    padding-bottom: 34px;
    color: #ff0000;

    div {
      font-family: Geometria-Heavy, sans-serif;
      max-width: 501px;
      margin-left: 100px;
      text-align: left;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
    }
  }

  &_inputs {
    display: flex;
    flex-direction: column;
    max-width: 463px;
    margin-left: 100px;
    margin-bottom: -4px;
  }

  .input_agreement {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }

  &_btn {
    display: flex;
    flex-direction: column;
    padding-bottom: 132px;
    max-width: 463px;
    margin-left: 100px;

    // opacity: 0;
    // visibility: hidden;
    transition: all 0.5s ease;

    &_active {
      visibility: visible;
      opacity: 1;
    }
  }

  &_mobileImg {
    display: none;
  }
}

@media (max-width: 1024px) {
  .stepFour {
    // background-image: none;
    background-size: 298px;
    background-position-x: 100%;
    background-position-y: 0%;
  }
  .desc {
    background-image: url(../../../assets/images/BigSteps/StepFour/yellow-circle.png);
    background-repeat: no-repeat;
    background-position-y: -40%;

    &_wrapper {
      background-image: none;
      max-width: 463px;
      padding: 0;
    }

    &_img {
      display: none;
    }

    &_dotsImg {
      display: none;
    }

    &_img {
      width: 100%;
    }

    &_text {
      margin-bottom: 40px;
      width: 100%;
      padding-top: 40px;

      .text_title {
        font-size: 36px;
        line-height: 120%;
      }

      .text_desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
      }

      .text_step {
        display: block;
        font-weight: 900;
        font-family: Geometria-Heavy, sans-serif;
        font-size: 36px;
        line-height: 120%;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #000000;
        color: transparent;
      }
    }
  }

  .form {
    &_wrapper {
      background-image: none;
      padding: 0 20px;

      &::after {
        background-image: none;
      }
    }

    &_catsTalk {
      display: none;
    }

    &_title {
      max-width: 463px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 22px;
      line-height: 100%;
      background-image: none;
      text-transform: unset;
      margin: 0 auto;

      div {
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 10px;
        margin: 0;
        margin-bottom: 10px;
      }
    }

    &_inputs {
      margin: 0 auto;
    }

    &_btn {
      padding: 0;
      margin: 0 auto;
      margin-bottom: 14px;
    }

    &_mobileImg {
      display: flex;
      padding-bottom: 200px;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 463px;
      margin: 0 auto;

      .mobileImg_text {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        max-width: 75%;
        position: absolute;
        left: 0;
        right: 0;
        padding: 16px 52px 16px 52px;
        border: 1px solid #ff0000;
        border-radius: 24px;
        color: #333333;
        z-index: -1;
        @media (max-width: 1024px) {
          top: 20%;
        }
        @media (max-width: 450px) {
          padding-left: 20px;
        }
        div {
          font-family: Geometria-Heavy, sans-serif;
        }

        div:last-child {
          color: #ff0000;
          height: 80px;
          overflow: hidden;
        }
      }

      img {
        width: 50%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 767px) {
  .stepFour {
    background-image: none;
  }
  .desc {
    padding: 0 20px;

    &_wrapper {
      background-image: none;
      padding: 0;
    }
  }
  .form {
    &_inputs {
      background-image: none;
    }
  }
}

$progressColor: orange;
$bgColor: white;

@mixin label {
  font-family: Geometria-Bold, sans-serif;
  font-size: 60px;
  line-height: 100%;
  color: #ff0000;

  @media (max-width: 1024px) {
    font-size: 32px;
    line-height: 100%;
  }
}

.stepTwo {
  padding: 40px 130px 0 86px;
  margin: 0 auto 127px auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}
.girl{
  position: absolute;
  top: 15%;
  left: 12%;
  @media(max-width:1266px){
    left: 6%;
  }
  @media(max-width:1170px){
    width: 22%;
    top: 21%;
  }
  @media(max-width:1024px){
    width: 17%;
    top: 33%;
    left: 52%;
  }
  @media(max-width:700px){
    width: 21%;
    top: 33%;
  }
  @media(max-width:615px){
    top: 27%;
  }
  @media(max-width:500px){
    top: 25%;
    width: 27%;
    left: 55%;
  }
  @media(max-width:415px){
    top: 25%;
    width: 32%;
    left: 57%;
  }
  @media(max-width:375px){
    top: 26%;
    left: 54%;
    width: 36%;
  }
}
.morePresents {
  display: flex;
  flex-direction: column;
  &__text {
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 100%;
    color: #333333;
    margin-bottom: 32px;
    @media(max-width: 450px) {
      font-size: 22px;
    }
  }
  &__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    @media(max-width:450px){
      flex-direction: column;
    }
  }
  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 20%;
    img {
      margin-bottom: 20px;
    }
  }
  .block__title {
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #ff0000;
  }
  .block__desc {
    font-family: Geometria;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #333333;
  }
}
.desc {
  &_wrapper {
    display: flex;
    flex-direction: row;
    // padding: 40px 130px 0 130px;
    //padding-top: 40px;
    position: relative;
    @media (max-width: 1300px) {
      background-position-x: 0%;
    }
  }
  &_img {
    margin-right: 20px;
    width: 218px;
    img{
      //width: 100%;
      width: 218px;
      height: 218px;
    }


  }
  &_dotsImg {
    z-index: -1;
    max-width: 381px;
    position: absolute;
    top: -50px;
    right: 0;
    img {
      width: 100%;
    }
  }
  &_text {
    max-width: 695px;
    margin-bottom: 50px;
    .text_step {
      display: none;
    }
    .text_title {
      @include label;
      text-transform: uppercase;
      font-weight: 900;
      margin-top: 21px;
      margin-bottom: 12px;
      font-family: Geometria-Heavy, sans-serif;
    }
    .text_desc {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      max-width: 560px;
      p {
        margin: 0;
      }
      span {
        color: #ff0000;
        font-weight: 600;
      }
    }
  }
}

.form {
  &_wrapper {
    padding-bottom: 30px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 12%;
      right: 0;
      width: 300px;
      height: 100px;
      background-image: url(../../../assets/images/BigSteps/StepOne/dots2.png);
      background-repeat: no-repeat;
      z-index: -1;
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 36%;
      right: 0;
      width: 416px;
      height: 250px;
      background-image: url(../../../assets/images/BigSteps/StepFour/border-bottom.png);
      background-repeat: no-repeat;
      background-size: auto;
      z-index: -1;
    }
  }
  &_catsTalk {

    .form_catsTalk_text {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    position: absolute;
    top: 5%;
    left: 4%;
    max-width: 352px;
    padding: 32px;
    border: 1px solid #ff0000;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
    border-radius: 24px;
    @media (max-width: 1200px) {
      max-width: 175px;
      padding: 12px;
    }
    &::after {
      content: "";
      position: absolute;
      border-left: 24px solid transparent;
      border-top: 20px solid #ff0000;
      bottom: -20px;
      left: 50px;
    }
    span {
      color: #ff0000;
    }
  }
  &_title {
    padding-top: 116px;
  
    padding-bottom: 34px;
    color: #ff0000;
    div {
      font-family: Geometria-Heavy, sans-serif;
      max-width: 501px;
      margin-left: auto;
      margin-right: 65px;
      text-align: left;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      span{
        font-family: Geometria-Heavy, sans-serif;
        color: #000;
      }
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    max-width: 463px;
    margin-left: auto;
    margin-right: 100px;
    margin-bottom: -4px;
  }
  .input_agreement {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }
  &_btn {
    display: flex;
    flex-direction: column;
    padding-bottom: 132px;
    max-width: 463px;
    margin-left: auto;
    margin-right: 100px;
  }
  &_mobileImg {
    display: none;
  }
}
.btn_container{
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .stepFour {
    // background-image: none;
    background-size: 298px;
    background-position-x: 100%;
    background-position-y: 0%;
  }
  .desc {
    background-image: url(../../../assets/images/BigSteps/StepFour/yellow-circle.png);
    background-repeat: no-repeat;
    background-position-y: -28px;
    &_wrapper {
      background-image: none;
      max-width: 463px;
      padding: 0;
    }
    &_img {
      display: none;
    }
    &_dotsImg {
      display: none;
    }
    &_img {
      width: 100%;
    }
    &_text {
      margin-bottom: 40px;
      width: 100%;
      padding-top: 40px;
      .text_title {
        font-size: 36px;
        line-height: 120%;
      }
      .text_desc {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
      }
      .text_step {
        display: block;
        font-weight: 900;
        font-family: Geometria-Heavy, sans-serif;
        font-size: 36px;
        line-height: 120%;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #000000;
        color: transparent;
      }
    }
  }

  .form {
    &_wrapper {
      background-image: none;
      padding: 0 20px;
      &::after {
        background-image: none;
      }
      &::before{
        background-image: none;
      }
    }
    &_catsTalk {
      display: none;
    }
    &_title {
      
      max-width: 463px;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 22px;
      line-height: 100%;
      background-image: none;
      text-transform: unset;
      margin: 0 auto;
      div {
        font-size: 22px;
        line-height: 100%;
        margin-bottom: 10px;
        margin: 0;
        margin-bottom: 10px;
      }
    }
    &_inputs {
      margin: 0 auto;
    }
    &_btn {
      padding: 0;
      margin: 0 auto;
      margin-bottom: 14px;
    }
    &_mobileImg {
      display: flex;
      padding-bottom: 200px;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      max-width: 463px;
      margin: 0 auto;
       
      .modile_long_text{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .mobileImg_text {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        max-width: 72%;
        position: absolute;
        left: 1%;
        top: 12%;
        padding: 16px 52px 16px 52px;
        border: 1px solid #ff0000;
        border-radius: 24px;
        color: #333333;
        z-index: -1;
        div {
          font-family: Geometria-Heavy, sans-serif;
        }
        div:last-child {
          color: #ff0000;
        }
      }
      img {
        width: 50%;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 767px) {
  .stepFour {
    background-image: none;
  }
  .desc {
    padding: 0 20px;
    &_wrapper {
      background-image: none;
      padding: 0;
    }
  }
  .form {
    &_inputs {
      background-image: none;
    }
  }
}

@import "index";

header {
  background-color: $primary;
  height: 60px;
  font-size: 30px;

 div{
   max-width: 1400px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   flex-wrap: wrap;
   @media (max-width: 420px) {
    justify-content: center;
  }
 }


  img {
    height: 50px;
    margin: 6px 0 6px 130px;
    @media (max-width: 768px) {
      margin-left: 20px;
    }
    @media (max-width: 414px) {
      margin-left: 0px;
    }
  }

  img:last-child{
    margin-right: 132.42px;
    // height: 24.17px;
    @media(max-width:768px){
      opacity: 0.7;
      margin-right: 23.42px;
    }
  }
}

footer{
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  div, a {
    color: red;
  }
}

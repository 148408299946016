@import "../../index";

.wrapper {

  .stepway {
    background: #ffffff;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);

    @media (max-width: 1024px) {
        display: none;
      }

    &_wrapper {
      max-width: 1400px;
      margin: 0 auto;
      padding: 15.5px 130px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .step {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: $secondary;
    text-decoration: none;

    &.active {
      margin-top: -5.5px;
      margin-bottom: -5.5px;
      padding-bottom: 10px;
      border-bottom: 4px solid #ff0000;
    }
  }

  .content {
    position: relative;
    max-width: 1400px;
    margin: 0 auto;
    .progressWrapper {
      position: absolute;
      left: 76px;
      top: 30px;
      height: 238px;
      width: 238px;
      @media (max-width: 1024px) {
        display: none;
      }

      .progress {
        position: relative;
        height: 238px;
        width: 238px;
        border-radius: 50%;
        z-index: -1;

        &:after {
          border: none;
          position: absolute;
          top: 10px;
          left: 10px;
          text-align: center;
          display: block;
          border-radius: 50%;
          width: 218px;
          height: 218px;
          background-color: white;
          content: "";
        }

        .firstHalf {
          position: absolute;
          clip: rect(0, 240px, 240px, 120px);
          background-color: $progressColor;
          border-radius: 50%;
          width: 238px;
          height: 238px;
          visibility: hidden;
        }

        &.over50 {
          .clipper {
            clip: rect(0, 240px, 240px, 0);
            transition: clip 0s ease 0.3s;
          }
          .firstHalf {
            visibility: visible;
            transition: visibility 0s ease 0.3s;
          }
        }

        .clipper {
          border-radius: 50%;
          height: 238px;
          width: 238px;
          position: absolute;
          clip: rect(0, 240px, 240px, 120px);

          .value {
            position: absolute;
            clip: rect(0, 120px, 240px, 0);
            width: 238px;
            height: 238px;
            border-radius: 50%;
            border: 10px solid $progressColor;
            box-sizing: border-box;
            transition: transform 1s ease;
          }
        }
      }

      .step1 .value {
        transform: rotate(72deg);
      }
      .step2 .value {
        transform: rotate(144deg);
      }
      .step3 .value {
        transform: rotate(216deg);
      }
      .step4 .value {
        transform: rotate(288deg);
      }
      .step5 .value {
        transform: rotate(360deg);
      }

    }


  }

}
@import "src/index";
@import "../StepFour/StepFour.module";


.wrapper {
  padding: 40px 130px 0 86px;
  margin: 0 auto 127px auto;
  position: relative;
  max-width: 1400px;

  @media (max-width: 1024px) {
    padding: 40px 20px 0 20px;
    margin-bottom: 30px;
  }

  .labelWrapper {
    display: flex;
    position: relative;

    @media (max-width: 1024px) {
      display: none;
    }

    img {
      width: 218px;
      height: 218px;
      margin-right: 20px;
    }

    div {
      display: flex;
      flex-direction: column;

      h1 {
        @include label;
        margin: 0;
        margin-top: 21px;
      }

      p {
        font-family: Geometria-Light, sans-serif;
        font-size: 18px;
        margin-top: 12px;
        width: 80%;
      }
    }

  }

  .labelWrapperMob {
    display: none;

    @media (max-width: 1024px) {
      display: flex;
      max-width: 560px;
      margin: auto;
    }

    img {
      position: absolute;
      top: -50px;
      left: 0;
      z-index: -1;
    }

    div {
      display: flex;
      flex-direction: column;

      .stepLabel {
        position: relative;
        margin-bottom: 0;
        margin-top: 0;
        line-height: 120%;
        z-index: 1;
        font-family: 'Geometria-Heavy', sans-serif;
        font-size: 36px;
        width: 11ch;
        text-transform: uppercase;
        color: transparent;
        stroke: black;
        stroke-width: 1px;
        -webkit-text-stroke-color: black;
        -webkit-text-stroke-width: 1px;
      }

      h1 {
        @include label;
        margin: 0;
        margin-top: 21px;

        @media (max-width: 1024px) {
          line-height: 120%;
        }
      }

      p {
        font-family: Geometria-Light, sans-serif;
        font-size: 16px;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

  }


  .content {
    display: flex;
    justify-content: space-around;

    @media (max-width: 1024px) {
      flex-direction: column;
      max-width: 560px;
      margin: auto;
    }

    .main {
      display: flex;
      flex-direction: column;
      width: 50%;

      @media (max-width: 1024px) {
        width: 100%;
      }

      .answers {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        min-height: 418px;
        margin-left: 40px;

        @media (max-width: 1024px) {
          margin-left: 0;
          min-height: auto;
        }

        .btnWrapper {
          visibility: hidden;
          //height: 0;
          opacity: 0;
          transition: all 0.5s ease;

          &._active {
            //height: auto;
            visibility: visible;
            opacity: 1;
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
        }

      }
    }

    .cat {
      display: flex;
      height: fit-content;
      margin-top: 100px;
      justify-content: flex-end;
      position: relative;
      width: 50%;
      img{
        position: absolute;
        top: 35px;
        left: 30%;
        z-index: 1;
        @media(max-width:1024px){
          width: 28%;
          top: 5px;
          right: -40px;
          left: unset;
        }
        @media(max-width:500px){
          top: 10px;
        }
        @media(max-width: 450px){
          top: 30px;
        }
        @media(max-width:400px){
          width: 40%;
        }
        @media(max-width:320px){
          top: 35px;
          width: 40%;
        }
      }
      img:first-child{
        transform: scaleX(-1);
      }
      @media (max-width: 1024px) {
        align-items: center;
        width: 100%;
        justify-content: space-between;
        display: grid;
        grid-template-columns: 8fr 2fr
      }

      .speech {
        font-size: 24px;
        border: 1px solid $primary;
        border-radius: 24px;
        width: 16ch;
        padding: 32px;
        position: absolute;
        top: -110px;
        right: 100px;
        text-align: center;
        background: white;
        &::after {
          content: "";
          position: absolute;
          border-right: 24px solid transparent;
          border-top: 20px solid #ff0000;
          bottom: -20px;
          left: 50px;
          @media(max-width:1024px){
            display: none;
          }
        }
        @media(max-width:1400px){
          left: 43%;
          right: unset;
        }
        @media (max-width: 1024px) {
          font-size: 16px;
          padding: 15px 60px 15px 30px;
          position: static;
          width: 120%;
          border-radius: 24px;
          margin-top: 58px;
        }

        p {
          width: 12ch;
          margin: auto;
          font-family: Geometria-Bold, sans-serif;

          @media (max-width: 1024px) {
            font-family: Geometria-Heavy, sans-serif;
          }
        }
      }

      .tickImg {
        position: absolute;
        top: 32px;
        right: 135px;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      .catBigImg {
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .catImg {
        display: none;
        width: 154px;
        height: 177px;

        @media (max-width: 1024px) {
          display: block;
        }
      }
    }
  }

}

.btnsWrapper {
  display: flex;
  justify-content: space-between;
}
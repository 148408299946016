@import "src/index";

.wrapper {
  max-width: 1400px;
  padding: 70px 78px 70px 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 0;
    padding: 0 20px;
    padding-bottom: 20px;
  }
  img:first-child {
    width: 100%;
    @media (max-width: 414px) {
      display: none;
    }
  }
  img:nth-child(2) {
    width: 100%;
    display: none;
    @media (max-width: 414px) {
      display: block;
    }
  }
  .popup {
    align-self: flex-start;
    border-left: 4px solid transparent;
    padding-left: 20px;
    button:first-child {
      font-family: Geometria-Heavy, sans-serif;
      color: $primary;
      z-index: 2;
      background: transparent;
      border: none;
      cursor: pointer;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 100%;
      width: 300px;
      text-align: left;
      padding: 0;
      @media(max-width:768px){
        font-size: 14px;
      }
    }
    .buttonToRegister{
      display: none;
      @media(max-width:414px){
        display: block;
        
      }
    }
    .popuptext {
      font-family: Geometria;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      margin-top: 16px;
      p {
        margin-bottom: 0;
      }
      @media(max-width:414px){
        margin-top: 10px;
        padding: 40px 24px;
        width: 95%;
        background-color: #fff;
        border-radius: 32px;
        margin: 0 auto;
     
      }
    }
    .popuptext_wrapper{
      display: none;
        @media(max-width: 414px){
          padding-top: 20px;
          padding-bottom: 20px;
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(255, 255, 255, 0.8);
          overflow-y: auto;
          z-index: 9999;
        }
    }
    @media (max-width: 768px) {
      border: none;
      padding-left: 11px;
      left: 4%;
      margin-top: 30px;
    }
    @media (max-width: 414px) {
      bottom: 12%;
      left: 7%;
    }
    &:hover {
      border-left: 4px solid #ff0000;
      @media (max-width: 414px) {
       border:none;
      }
    }
  }
  .buttonToRegister {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff0000;
    background: linear-gradient(135deg, #b1002c 0%, #ff0011 100%);
    border-radius: 31px;
    padding: 16px 24px;
    width: 100%;
    margin-bottom: 16px;
    transition: box-shadow 0.5s ease;
    color: white;
    text-transform: uppercase;
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    bottom: 20px;
    max-width: 217px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 44px;
    &:hover {
      box-shadow: 0 3px 9px 0 rgba(233, 30, 40, 0.5);
    }
    @media (max-width: 768px) {
      max-width: 95%;
      margin-right: 20px;
      margin-top: 24px;
    }
    @media (max-width: 414px) {
      padding: 10px 24px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

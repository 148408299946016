@mixin label {
  font-family: Geometria-Heavy, sans-serif;
  font-size: 60px;
  line-height: 100%;
  color: #ff0000;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 100%;
  }
}

* {
  box-sizing: border-box;
}

.stepFour {
  padding: 40px 130px 0 86px;
  margin: 0 auto 127px auto;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0;
    margin-bottom: 30px;
  }
}

.wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.circle{
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  @media(max-width:1024px){
    display: none;
  }
}

.desc {
  &_wrapper {
    display: flex;
    flex-direction: row;
    // padding: 40px 130px 0 130px;
    //padding-top: 40px;
    position: relative;
  }
  &_img{
    margin-right: 20px;
    width: 218px;
    img{
      //width: 100%;
      width: 218px;
      height: 218px;
    }
  }
  &_humanImg {
    z-index: -1;
    position: absolute;
    top: 13%;
    right: 2%;
    @media(max-width:1280px){
      top: 13%;
      right: -10%;
    }
    @media (max-width:1248px) {
      top: 46%;
      width: 424px;
    }
    img {
      width: 100%;
    }
  }
  &_text {
    max-width: 775px;
    margin-bottom: 140px;
    .text_step {
      display: none;
    }
    .text_title {
      @include label;
      text-transform: uppercase;
      font-weight: 900;
      margin-top: 21px;
      margin-bottom: 12px;
      font-family: Geometria-Heavy, sans-serif;
    }
    .text_desc {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 23px;
      color: #333333;
      max-width: 560px;
      div{
        margin-top: 30px;
      }
    }

    .text_desc2 {
      @media(min-width:1550px){
        width:85%;
      }
      @media(max-width:1420px){
        width:60%;      
      }
      @media (max-width:1248px) {
        width:85%;
      }
      @media (max-width:1178px) {
        width:65%;
      }
      @media (max-width:1130px) {
        width:45%;
      }
    }

  }
}

.form {
  &_wrapper {
    // background-image: url(../../../assets/images/BigSteps/StepFour/form-bg.png);
    // background-repeat: no-repeat;
    // background-position-y: 37%;
    // padding-bottom: 30px;
  }
  &_title {
    margin: 0 auto;
   
    padding-top: 116px;
    background-image: url(../../../assets/images/BigSteps/StepFour/borderTop.png);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: center;
    text-align: center;
    padding-bottom: 34px;
    font-size: 60px;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    @media(max-width:1300px){
      background-position-x: -5%;
    }
    span {
      font-family: Geometria-Heavy, sans-serif;
    }

    span:last-child {
      color: #ff0000;
    }
  }
  &_inputs {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    margin: 0 auto;
    margin-bottom: -4px;
    // background-image: url(../../../assets/images/BigSteps/StepFour/border-bottom.png);
    // background-repeat: no-repeat;
    // background-position-x: right;
    // background-position-y: bottom;
  }
  .input_agreement {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #333333;
    opacity: 0.5;
  }
  &_btn {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 210px;
    max-width: 1000px;
    padding-bottom: 132px;
    background-image: url(../../../assets/images/BigSteps/StepFour/border-bottom.png);
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: 90%;
    background-position-y: 50%;
    .btn_container {
      width: 366px;
      margin: 0 auto;
    }
  }
}

@media (max-width: 1024px) {
  .stepFour {
    // background-image: none;
    background-size: 298px;
    background-position-x: 100%;
    background-position-y: 0%;
  }
  .desc {
    background-image: url(../../../assets/images/BigSteps/StepFour/yellow-circle.png);
    background-repeat: no-repeat;
    background-position-y: -40%;
    &_wrapper {
      background-image: none;
      max-width: 560px;
      padding: 0;
    }
    &_humanImg {
      display: none;
    }
    &_img {
      display: none;
    }
    &_text {
      margin-bottom: 40px;
      width: 100%;
      padding-top: 40px;
      .text_title {
        font-size: 36px;
        line-height: 120%;
      }
      .text_desc {
        font-size: 16px;
        line-height: 20px;
      }
      .text_step {
        display: block;
        font-weight: 900;
        font-family: Geometria-Heavy, sans-serif;
        font-size: 36px;
        line-height: 120%;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #000000;
        color: transparent;
      }
    }
  }

  .form {
    &_wrapper {
      background-image: none;
      padding: 0 20px;
    }
    &_title {
      max-width: 560px;
      margin: 0 auto;
      padding-top: 0;
      padding-bottom: 32px;
      font-size: 22px;
      line-height: 100%;
      text-align: left;
      background-image: none;
      text-transform: unset;
    }
    &_inputs {
      background-image: none;
    }
    &_btn {
      max-width: 560px;
      padding: 0;
      background-image: none;
      .btn_container {
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .stepFour {
    background-image: none;
  }
  .desc {
    padding: 0 20px;
    &_wrapper {
      background-image: none;
      padding: 0;
    }
  }
  .form {
    &_inputs {
      background-image: none;
    }
  }
}

@import "src/index";


.btn {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #FF0000;
  background: linear-gradient(135deg, rgba(177,0,44,1) 0%, rgba(255,0,17,1) 100%);
  border-radius: 31px;
  padding: 8px 20px;
  width: 100%;
  margin-bottom: 16px;
  transition: box-shadow 0.5s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 9px 0 rgba(233, 30, 40, .5);
  }

  &:active {
    background: #B1002C;
  }

  @media (max-width: 425px) {
    // background: $primary;
    // padding: 0 20px;
    margin: auto;
  }

  p {
    align-self: center;
    margin: 0.5em;
    font-family: Geometria-Heavy, sans-serif;
    letter-spacing: 1px;
    color: white;
    font-size: 18px;
  }
}
@import "src/index";

.btn {
  font-family: Geometria-Heavy, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: $secondary;
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  margin: auto;
  border: 0;
  background: linear-gradient(90deg, rgba(255,235,0,1) 0%, rgba(255,158,0,1) 99%);
  border-radius: 31px;
  padding: 18px 26px 18px 25px;
  transition: box-shadow 0.5s ease;
  width: 18ch;

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 9px 0 rgb(224, 207, 6);
  }

  &:active {
    background: rgba(255,235,0,1);
  }
}
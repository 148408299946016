@import "src/index";

.wrapper {
  background-color: #ededed;
  width: 100%;
  // height: 599px;
  overflow-y: hidden;
  position: relative;
  .polygon {
    width: 100%;
    height: 100px;
    background: white;
    clip-path: polygon(0 102px, 0px 315px, 161% 0px, 99% 0);
    position: absolute;
    bottom: 0;
    background: #fff;
    @media (max-width: 900px) {
      clip-path: polygon(0 102px, 0px 244px, 179% 0px, 350% 0);
    }
  }

  @media (max-width: 768px) {
    height: auto;
  }

  .imagesWrapper {
    position: relative;
    max-width: 1400px;
    margin: auto;
    img {
      width: 100%;
    }

    @media (max-width: 1024px) {
      min-height: auto;
      height: auto;
    }
  }
}

@import "src/index";

.wrapper {
  // height: 950px;
  position: relative;
  padding-top: 150px;
  line-height: 3em;
  width: 80%;
  margin: 0 auto 70px auto;
  max-width: 1400px;
  @media (max-width: 1300px) {
    width: 90%;
  }

  h1 {
    font-size: 50px;
    font-family: Geometria-Heavy, sans-serif;
    width: 28ch;
    line-height: 1em;
    color: $secondary;
    @media (max-width: 768px) {
      font-size: 22px;
      width: unset;
    }
  }

  .emojiWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 52px;
    @media (max-width: 768px) {
      margin-top: 35px;
    }
    @media(max-width: 450px){
      justify-content: center;
    }
  }

  .winWrapper {
    // position: absolute;
    // bottom: 60px;
    // left: 0;
    display: flex;
    flex-direction: column;
    max-width: 980px;
    margin: 0 auto;
  }

  .winBtn {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #ff0000;
    background: linear-gradient(135deg, #b1002c 0%, #ff0011 100%);
    border-radius: 31px;
    padding: 16px 24px;
    width: 100%;
    margin-bottom: 16px;
    transition: box-shadow 0.5s ease;
    color: white;
    text-transform: uppercase;
    font-family: Geometria-Heavy, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 23px;
    bottom: 20px;
    max-width: 217px;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 44px;
    position: absolute;
    bottom: 8%;
    left: 33%;
    &:hover {
      box-shadow: 0 3px 9px 0 rgba(233, 30, 40, 0.5);
    }
    @media(max-width:768px){
      padding: 10px 0;
      max-width: 95%;
      position: relative;
      bottom: unset;
      left: unset;
    }
  }

  .tShirtImg {
    margin: 0 auto;
    width: 100%;
    &_mob{
      display: none;
      @media(max-width:414px){
        display: block;
        width: 106%;
      }
    }
    @media(max-width:414px){
      display: none;
    }
  }
}
